import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ContactPerson from "../components/contactPerson";
import { GatsbyImage } from "gatsby-plugin-image";
import growIdeas from "../assets/images/doodle-grow-ideas.svg";
import Hero from "../components/hero";

const Kultur = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "hero-culture.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      tools: file(relativePath: { eq: "tools.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 727
            placeholder: DOMINANT_COLOR
            layout: CONSTRAINED
          )
        }
      }
      kununu: file(relativePath: { eq: "kununu.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 364
            placeholder: DOMINANT_COLOR
            layout: CONSTRAINED
          )
        }
      }
      jannik: file(relativePath: { eq: "profiles/jannik-bischof.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
      sliders: allFile(
        sort: { fields: relativePath }
        filter: { relativePath: { glob: "culture/culture-slide-*" } }
      ) {
        nodes {
          publicURL
        }
      }
    }
  `);

  return (
    <Layout lang="de" langPath="nav.culture.link">
      <Seo
        title="Dein Platz zum Entfalten mitten in Hamburg"
        description="Wir sind eine inhabergeführte Agentur im Herzen Hamburgs. Hier kannst Du wirklich was bewegen und zeigen, wie viel kreative Exzellenz in Dir steckt."
        keywords="Agentur, Content-Management, Hamburg, Softwareentwicklung, Unternehmenskultur, Jobs"
      />
      <div className="parallax-translate bg-shape-vertigo"></div>
      <Hero
        headline="Willkommen bei Monday"
        subline="Wir sind eine inhabergeführte Agentur aus Hamburg. In unserem großzügigen Office am Fischmarkt hast Du freie Sicht auf den Hafen und Deine Zukunft."
        section="Kultur"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Kultur"
        />
      </Hero>
      <div className="container mt-3 mb-5 mb-md-6">
        <div className="row align-items-start">
          <div className="col-md-6 d-none d-sm-block">
            <img className="w-75" alt="Platz zum Entfalten" src={growIdeas} />
          </div>
          <div className="col-md-6">
            <h2>Platz zum Entfalten</h2>
            <p>
              Klar wollen wir, dass unsere Kunden Erfolg haben. Aber für uns
              alle hier gehört auch der Weg dorthin zum Ziel. Darum schaffen wir
              eine Kultur, die auf Zusammenarbeit basiert – mit Mut und ohne
              Angst vor Fehlern, denn die machen uns nur besser. Bei Monday
              kannst Du wirklich was bewegen und zeigen, wie viel kreative
              Exzellenz in Dir steckt.
            </p>
            <p>
              Diese Einstellung spiegelt sich auch in der
              Mitarbeiterzufriedenheit wider. Auf der Bewertungsplattform Kununu
              erreichten wir herausragende Ergebnisse. So wurden wir 2024 erneut
              mit dem Kununu Top Company Award ausgezeichnet, was unser
              Engagement für die Erfüllung der Bedürfnisse unseres Teams
              unterstreicht.
            </p>
            <GatsbyImage
              image={images.kununu.childImageSharp.gatsbyImageData}
              alt="Kununu Top Company Award"
              title="Kununu Top Company Award"
            />
            <br />
            <Link
              to="/jobs"
              className="btn bg-monday-skyblue text-color-white mt-3"
            >
              Zu den Jobs
            </Link>
          </div>
        </div>
      </div>

      <div className="container mb-md-0">
        <div
          className="row vertical-spacing mb-3 reveal"
          data-spacing-bottom="team"
        >
          <h2 className="col-12 text-center">Gute Gründe, zu uns zu kommen</h2>
        </div>
        <div className="row align-items-start">
          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 bg-shape-cross bg-position-tl bg-size-50 vertical-offset"
            id="team"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                1
              </span>
              Dein Weg
            </h3>
            <p>
              Bei uns kannst Du Initiative und Verantwortung übernehmen, eigene
              Ideen platzieren und entwickeln. Moderne Tools ermöglichen Dir
              Exzellenz bei Deiner Arbeit. Und wir bieten Dir gezielte Coaching-
              und Fortbildungsmöglichkeiten. Aber vor allem feiern wir Deine
              kleinen und großen Erfolge – denn schließlich haben wir alle was
              davon.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 offset-1 pb-4 pb-md-0 mb-4 mb-md-6 bg-shape-dots bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                2
              </span>
              Deine Zeit
            </h3>
            <p>
              Bei uns organisierst Du Dich selbst und arbeitest
              eigenverantwortlich. Egal ob Mobil zu Hause oder in unserem Office
              am Fischmarkt. Jeden Monat kannst Du wählen, wie viele Stunden Du
              arbeiten möchtest. Sollte es dann doch mal mehr geworden sein,
              entscheide selbst: Mehr Geld oder mehr Freizeit? Liegt ganz bei
              Dir! Freitags kommen wir zusammen und tauschen uns über die
              Ereignisse der Woche aus. Natürlich gibt es auch regelmäßig
              Team-Events wie unser Jahres-Kick-Off und unseren Stammtisch. Und
              am Wochenende? Da freuen wir uns auf Montag.
            </p>
          </div>

          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 offset-md-1 bg-shape-circle bg-position-tl bg-size-50 vertical-offset"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                3
              </span>
              Dein Team
            </h3>
            <p>
              Uns ist ein freundliches Umfeld wichtig, in dem Du sicher sein
              kannst, Teil eines tatendurstigen Teams zu sein. Du stellst Dich
              Aufgaben, an denen Du wächst, mit erfahrenen Kollegen an Deiner
              Seite, von denen Du jede Menge lernst. Flache Hierarchien und
              entspannte Chefs? Die gibt’s obendrauf.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 pb-4 pb-md-0 mb-4 offset-1 bg-shape-square bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                4
              </span>
              Dein Ding
            </h3>
            <p>
              Am großen Tisch in unserer zentralen Lounge wird gemeinsam
              gegessen und gefeiert, in unseren „Think Tanks“ in Ruhe gegrübelt.
              Eine richtige Kaffeemaschine, Kicker und Konsole stehen auch
              bereit. Und Du entscheidest, ob Du mit Windows, macOS oder Linux
              arbeiten willst. Ganz nach Deinem Geschmack.
            </p>
          </div>
        </div>
      </div>

      <div className="container mt-md-3 mb-5 mb-md-6 reveal">
        <div className="row align-items-center">
          <div className="col-md-6 mb-3">
            <GatsbyImage
              image={images.tools.childImageSharp.gatsbyImageData}
              className="w-75"
              alt="Unsere Tools"
            />
          </div>
          <div className="col-md-6">
            <h3>Tools, die wir lieben</h3>
            <p>
              Die digitale Zukunft veränderst Du nicht mit Werkzeugen von
              gestern. Darum arbeitest Du bei uns mit modernsten Tools für
              Entwickler von Software und Web-Anwendungen.
            </p>
            <Link
              to="/leistungen"
              className="btn bg-monday-skyblue text-color-white"
            >
              Unsere Leistungen, Deine Arbeit
            </Link>
          </div>
        </div>
      </div>

      <div
        className="float-teaser float-teaser-right bg-monday-yellow vertical-offset reveal"
        data-offset="50%"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-6">
              <div className="float-teaser-body p-3 py-md-4 pl-lg-5">
                <h3 className="text-size-5">Komm an Bord!</h3>
                <p className="h4">
                  Genau Dein Ding? Dann bewirb Dich jetzt und werde Teil unseres
                  Teams.
                </p>
                <Link to="/jobs" className="btn">
                  Aktuelle Jobangebote
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-5 fullwidth reveal">
        <div className="swiper-container" data-loop data-center>
          <div className="swiper-wrapper is-gallery">
            {images.sliders.nodes.map((image, i) => {
              return (
                <div className="swiper-slide col-8 col-md-auto" key={i}>
                  <img alt="Culture Gallery Slider" src={image.publicURL} />
                </div>
              );
            })}
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>

      <div
        className="content-container vertical-spacing bg-monday-skyblue bg-pattern-cross text-color-white text-center parallax-background"
        data-spacing-bottom="employees"
      >
        <div className="container pt-4 pt-md-5 pb-3">
          <div className="row">
            <div className="col-12 d-flex flex-column align-items-center mb-3">
              <h2>Das sagen Leute, die es wissen müssen</h2>
            </div>
          </div>
        </div>
      </div>

      <div
        id="employees"
        className="swiper-container container vertical-offset reveal mb-5 mb-md-6"
        data-offset="50%"
      >
        <div className="swiper-wrapper reveal">
          <div className="swiper-slide col-12 col-md-6 col-xl-4">
            <div className="p-3 bg-monday-yellow h-100 d-flex flex-column justify-content-between align-items-start">
              <div className="mb-3 w-100">
                <p>
                  Am besten finde ich die familiäre und lockere Atmosphäre bei
                  Monday. Alle Kollegen helfen einander und teilen ihre
                  Fähigkeiten. Bei unseren Kunden sind wir auch überwiegend als
                  lockere Experten bekannt. Wir bekommen alles hin und versuchen
                  dabei unseren modernen Spirit zu übertragen.
                </p>
              </div>
              <p className="text-sans w-100">
                Leon
                <br />
                Software Developer
              </p>
            </div>
          </div>
          <div className="swiper-slide col-12 col-md-6 col-xl-4">
            <div className="p-3 bg-monday-yellow h-100 d-flex flex-column justify-content-between align-items-start">
              <div className="mb-3 w-100">
                <p>
                  Was mir besonders gefällt, ist die Kommunikation. In den
                  gemeinsamen Runden am Freitag teilt jeder seine Erfahrungen
                  der Woche und wenn ein größeres Projekt abgeschlossen ist,
                  wird das mit einer Pizzapräsentation gefeiert bei der während
                  des Essens die Ergebnisse vorgestellt werden.
                </p>
              </div>
              <p className="text-sans w-100">
                Elena
                <br />
                Marketing Manager
              </p>
            </div>
          </div>
          <div className="swiper-slide col-12 col-md-6 col-xl-4">
            <div className="p-3 bg-monday-yellow h-100 d-flex flex-column justify-content-between align-items-start">
              <div className="mb-3 w-100">
                <p>
                  Ich finde die flexible Arbeitszeitgestaltung richtig gut. Wenn
                  in einem Monat mal etwas mehr Arbeit anfällt, kann ich mir die
                  geleisteten Überstunden direkt ausbezahlen lassen oder in
                  einem anderen Monat abfeiern. Da hat man gleich eine ganz
                  andere Motivation bei der Arbeit.
                </p>
              </div>
              <p className="text-sans w-100">
                Jonas
                <br />
                Software Developer
              </p>
            </div>
          </div>
        </div>
        <div className="swiper-pagination d-xl-none"></div>
      </div>
      <ContactPerson
        headline="Wir freuen uns von Dir zu hören."
        text="Du kannst was? Du bist sympathisch? Du kannst mit Kunden? Perfekt. Dann sollten wir uns kennenlernen. Denn auf diese Dinge kommt es in unserem Team an."
        contactEmail="jobs@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Jannik Bischof, Leiter Recruiting"
      >
        <GatsbyImage
          image={images.jannik.childImageSharp.gatsbyImageData}
          alt="Jannik Bischof"
        />
      </ContactPerson>
    </Layout>
  );
};

export default Kultur;
